<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Relatório de Transmissões por Revendedor
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data de Referência:</strong> {{ data }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <v-container fluid class="pt-0"  v-else>
        <v-col>
          <div class="box-table">
            <div class="abas" width="100%">
              <v-tabs
                v-model="tab"
                background-color="#FFF"
                height="30px">
                <v-tab href="#tab-1" class="abas-item">
                  Jogos
                </v-tab>

                <v-tab href="#tab-2" class="abas-item">
                  Venda de Créditos
                </v-tab>
              </v-tabs>
            </div>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item
                :key="1"
                :value="'tab-1'">
                <v-data-table
                  :headers="headers"
                  :items="listaItens"
                  :items-per-page="5"
                  width="300px"
                  flat
                  dense
                  @click:row="opemDadosJogos"
                > 
                  <template v-slot:top>
                    <!-- <BrToolBar @search="buscar" :configFilter="{ getItens: getItens, jsonData: true }" /> -->
                  </template>



                  <!-- <template v-slot:item.value="{ item }">
                    {{ item.value | currency }}
                  </template>

                  <template v-slot:item.gameNumber="{ item }">
                    {{ item.gameNumber }}
                  </template>

                  <template v-slot:item.created="{ item }">
                    {{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}
                  </template>

                  <template v-slot:item.deleted="{ item }">
                    <span v-if="isDeleted(item)">
                      {{ item.deleted | datas('DD/MM/YYYY HH:mm:ss') }}
                    </span>

                    <span v-else>
                      -
                    </span>
                  </template> -->


                  <template
                    v-slot:body="{ items }"
                  >

                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.gameNumber"
                        :class="{ cancelado: isDeleted(item) }"
                        class="box-table-row"
                        @click="opemDadosJogos(item)"
                      >
                        <td>{{ item.gameNumber }}</td>
                        <td>{{ item.sweepstakes.cod }}</td>
                        <td>{{ item.codId }}</td>
                        <td>{{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-center" v-if="isDeleted(item)">{{ item.deleted | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-end">{{ item.value | currency }}</td>
                      </tr>

                      <tr class="">
                        <th :colspan="headers.length">Total de jogos: {{ listaItens.length }}</th>
                      </tr>

                      <tr class="">
                        <th :colspan="headers.length - 1">Total de jogos não deletados: {{ listTransmissionsNotDeleted.length }}</th>

                        <th class="text-end">Valor: {{ totalValoresTransmissoes | currency}}</th>
                      </tr>
                      <tr class="">
                        <th :colspan="headers.length - 1">Total de jogos deletados: {{ listTransmissionsDeleted.length }}</th>
                        <th class="text-end">Valor: {{ totalValoresTransmissoesDeleted | currency}}</th>
                      </tr>
                    </tbody>
                  </template>

                  <template slot="body.append">
                    
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item
                :key="2"
                :value="'tab-2'">
                <v-data-table
                  :headers="headersListCreditosVendidos"
                  :items="listCreditosVendidos"
                  :items-per-page="5"
                  width="300px"
                  flat
                  dense
                  @click:row="opemDadosJogos"
                > 
                  <template v-slot:top>
                    <!-- <BrToolBar @search="buscar" :configFilter="{ getItens: getItens, jsonData: true }" /> -->
                  </template>

                  <template
                    v-slot:body="{ items }"
                  >

                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.cod"
                        :class="{ cancelado: isCancelado(item) }"
                        class="box-table-row"
                      >
                        <td>{{ item.cod }}</td>
                        <td class="text-center" v-if="isCancelado(item)">{{ item.updated | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-center">{{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-end">{{ item.value | currency }}</td>
                      </tr>

                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length">Total de jogos: {{ listCreditosVendidos.length }}</th>
                      </tr>

                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos não cancelados: {{ listCreditsNotCanceled.length }}</th>

                        <th class="text-end">Valor: {{ totalValoresVendasCreditos | currency}}</th>
                      </tr>
                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos cancelados: {{ listCreditsCanceled.length }}</th>
                        <th class="text-end">Valor: {{ totalValoresCreditsCanceled | currency}}</th>
                      </tr>
                    </tbody>
                  </template>

                  <!-- <template v-slot:item.value="{ item }">
                    {{ item.value | currency }}
                  </template>

                  <template v-slot:item.created="{ item }">
                    {{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}
                  </template> -->
<!-- 
                  <template slot="body.append">
                    <tr class="">
                      <th colspan="4">Qtde. de transmissões: {{ listCreditosVendidos.length }}</th>
                    </tr>

                    <tr class="">
                      <th>Total: {{ totalValoresVendasCreditos | currency}}</th>
                    </tr>
                  </template> -->
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
                
          </div>
        </v-col>
      </v-container>
    </v-card>

    <ModalJogos />
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import date from '@/views/components/date'

import moment from 'moment'

import validacao from './validacao'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalTransmissoes',
  mixins: [validationMixin],
  components: {
    ModalJogos: () => import('./modalJogos'),
    Modal: () => import('@/views/components/modal'),
    date
  },
  data: () => ({
    loadingModal: false,
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    data: null,
    revendedor: '',
    tab: 'tab-1',
    headers: [
      { align: 'start', class: 'table-header', text: 'Nr. Jogo', value: 'gameNumber' },
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'codId' },
      { align: 'start', class: 'table-header', text: 'Código do Bilhete', value: 'codId' },
      { align: 'start', class: 'table-header', text: 'Data de Transmissão', value: 'created' },
      { align: 'center', class: 'table-header', text: 'Data do Cancelamento', value: 'deleted' },
      { align: 'end', class: 'table-header', text: 'Valor', value: 'value' },
    ],

    headersListCreditosVendidos: [
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'center', class: 'table-header', text: 'Data do Cancelamento', value: 'updated' },
      { align: 'center', class: 'table-header', text: 'Data da Compra', value: 'created' },
      { align: 'end', class: 'table-header', text: 'Valor', value: 'value' },
    ]
  }),
  
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes', 'listTransmissionsNotDeleted', 'listTransmissionsDeleted']),
    ...mapGetters('vendasCreditos', ['listCreditosVendidos', 'listCreditsNotCanceled', 'listCreditsCanceled']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    totalValoresTransmissoes () {
      return this.listTransmissionsNotDeleted.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresTransmissoesDeleted () {
      return this.listTransmissionsDeleted.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresCreditsCanceled () {
      return this.listCreditsCanceled.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresVendasCreditos () {
      return this.listCreditsNotCanceled.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    }
  },
  mounted () {
    const self = this
    Events.$on('modal::historicoVendas::transmissoes', async (e) => {
      self.activeModal = true
      self.loadingModal = true

      self.data = self.displayDate(e.data, e.hasMonthOrDate)
      self.revendedor = e.entity.entity_cod

      self.formatDate(e)

      await self.getItens({ dtInicial: self.dtInicial, dtFinal: self.dtFinal, entityId: e.entity.entity_id })

      await self.getVendasCreditos({ dtStart: self.dtInicial, dtEnd: self.dtFinal, entityId: e.entity.entity_id })

      self.loadingModal = false

      // const data = this.listaItens.filter(item => {
      //   return moment(item.deleted).format('DD/MM/YYYY') > '01/01/1970'
      // })
      // console.log('transmission', data)
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas', 'limparItem']),
    ...mapActions('vendasCreditos', {
      getVendasCreditos: 'listaCreditosVendidos'
    }),
    openModal () {
      this.activeModal = true
    },

    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::jogos', { val, data: this.data, revendedor: this.revendedor })
    },

    closeModal () {
      this.activeModal = false
      this.tab = 'tab-1'
      this.limparItem()
    },

    formatDate (data) {
      if (data.hasMonthOrDate === 2) {
        this.dtInicial = moment(data.data, 'MM/YYYY').startOf('month').format('YYYY-MM-DD')
        this.dtFinal = moment(data.data, 'MM/YYYY').endOf('month').format('YYYY-MM-DD')

        return
      } 

      this.dtInicial = moment(data.data).format('YYYY-MM-DD')
      this.dtFinal = moment(data.data).format('YYYY-MM-DD')
    },

    isDeleted (data) {
      if (moment(data.deleted).format('DD/MM/YYYY') > '01/01/1970'){
        return true
      }

      return false
    },

    isCancelado (data) {
      if (data.status === 'C'){
        return true
      }

      return false
    },

    displayDate (date, type) {
      if (type === 2) return moment(date).format('MM/YYYY')

      return moment(date).format('DD/MM/YYYY')
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::transmissoes')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';

  .box-table {
    .cancelado {
      color: #F00;
    }

    &-row {
      cursor: pointer;
    }
  }
</style>